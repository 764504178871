<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="$vuetify.rtl"
    :src="drawerImage ? image : ''"
    :mini-variant="mini"
    :mini-variant-width="110"
    app
    width="260"
  >
    <template v-if="drawerImage" #img="props">
      <v-img :key="image" :gradient="gradient" v-bind="props" />
    </template>

    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3 mb-2" />

      <v-list-item-title class="mb-3 px-5 pt-2 primary--text font-weight-medium site-discription">
        IOT Platform ans Solution
      </v-list-item-title>

      <!-- <div class="mb-3 px-5 pt-2 primary--text font-weight-bold sub-title">
        IOT Platform ans Solution
      </div> -->

      <!-- <p class="pr-5 mb-1 text-right">
        Vuex Pathify<br>跨組件傳值
      </p>
      <p class="pr-5 mb-0 text-right hint-text">
        變更輸入內容看看結果
      </p>
      <div class="d-flex justify-end">
        <input
          v-model="msg"
          placeholder="Type something..."
          class="mx-3 mb-3 text-right input-new"
        >
      </div>

      <h1 class="pr-5 mb-3 text-right">
        {{ msg }}
      </h1> -->

      <v-divider class="mx-3 mb-2" />

      <default-list :items="items" />
    </div>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { get, sync } from 'vuex-pathify'

export default {
  name: 'DefaultDrawer',

  components: {
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        './List'
      )
  },

  computed: {
    // msg: sync('app/message'),
    ...get('user', ['dark', 'gradient', 'image']),
    ...get('app', ['items', 'version']),
    ...sync('app', ['drawer', 'drawerImage', 'mini'])
  }
}
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
  .hint-text
    font-style: italic
  .site-discription
    font-size: 16px
</style>
